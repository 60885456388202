@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  color: white;
  margin: 0;
}

body {
  background-color: #5860E9;
  font-family: "Montserrat", sans-serif;
  margin: auto;
}

h1 {
  font-size: 60px;
  line-height: 70px;
}

h2 {
  font-size: 30px;
  line-height: 40px;
}

a:link, a:visited, a:active, a:hover {
  text-decoration: none;
}

button {
  background-color: inherit;
  border-radius: 10px;
  border: 2px white solid;
  cursor: pointer;
}
button:focus, button:hover, button:active {
  outline: none;
  background-color: #5860E9;
}
button:disabled {
  background-color: red;
}

input:focus {
  outline: none;
  border: 2px black solid;
}

.socialNetworks-wrapper a {
  margin: 0 10px;
}

.globalFooter {
  height: 50px;
  padding: 50px;
  display: flex;
  justify-content: space-between;
}

.popUp-window {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}
.popUp-box {
  background-color: #5860E9;
  width: 50vw;
  padding: 50px;
  position: relative;
  transition: margin-left 0.4s;
  margin: auto;
}
.popUp-box-wrapper {
  margin: auto;
}
.popUp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popUp-input {
  margin: 30px 0;
  font-size: 20px;
  line-height: 30px;
  padding: 5px 20px;
  color: #5860E9;
  width: 90%;
}
.popUp-button {
  width: 150px;
  height: 44.6808510638px;
  margin-top: 15px;
}
.popUp-button:focus, .popUp-button:hover, .popUp-button:active {
  background-color: #8860D0;
}
.popUp-title {
  font-size: 25px;
}
.popUp-close {
  position: absolute;
  right: 50px;
  top: 30px;
  border: none;
  font-size: 25px;
  line-height: 30px;
}
.popUp-error {
  height: 0.9rem;
  color: #C1C8E4;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.globalHeader {
  background: linear-gradient(rgba(255, 255, 255, 0.3), #5860E9);
  padding: 100px;
}

.prodArea {
  display: flex;
}
.prodArea .prodCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px;
  padding: 30px;
}
.prodArea .prodCard p {
  max-width: 80%;
  margin-bottom: 40px;
}
.prodArea .prodCard hr {
  margin: 20px 0;
}
.prodArea .prodCard .prodIcon {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 70%;
  border-radius: 10px;
}
.prodArea .prodCard .img-wrapper {
  text-align: center;
}
.prodArea .prodCard header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.prodArea .prodCard header .h2-wrapper {
  min-height: 100px;
  display: flex;
  align-items: center;
}
.prodArea .card1 {
  background-color: #5AB9EA;
}
.prodArea .card1-btn {
  width: 150px;
  height: 44.6808510638px;
  margin: 5px;
}
.prodArea .card2 {
  background-color: #84CEEB;
}
.prodArea .card2-btn {
  width: 150px;
  height: 44.6808510638px;
  margin: 5px;
}
.prodArea .card3 {
  background-color: #8860D0;
}
.prodArea .card3-btn {
  width: 150px;
  height: 44.6808510638px;
  margin: 5px;
}

/*# sourceMappingURL=main.css.map */

@media screen and (max-width: 425px) {
  .globalHeader {
    padding: 30px;
  }
  .globalHeader h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .globalHeader h1 span {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .globalHeader {
    padding: 30px;
  }
  .globalHeader h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .globalHeader h1 span {
    font-size: 20px;
    line-height: 30px;
  }

  .prodArea {
    display: flex;
    flex-wrap: wrap;
  }

  .globalFooter {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .globalFooter span {
    margin: 10px;
  }
  .globalFooter .socialNetworks-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .globalFooter .socialNetworks-wrapper a {
    margin: 10px;
  }

  .popUp-box {
    text-align: center;
    width: auto;
  }
}

/*# sourceMappingURL=mobile.css.map */

